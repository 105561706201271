<template>
  <div>
    <common-header ref="header"></common-header>
    <div :class="isWeiXin() ? 'home-fixed-weixin':'home-fixed'">
    <div class="content-fixed">
      <div class="shopping-home warp" ref="searchbox">
        <!-- 搜索 start -->
        <div class="search-box">
          <div class="input-search">
            <input class="search-input" type="text" placeholder="搜索商品" v-model="searchText">
            <i class="icon-search-fdj" v-if="searchText == ''"></i>
          </div>
          <div class="search-btn jb-r-l" @click="searchFuc">搜索</div>
        </div>
        <!-- 搜索 end -->
      </div>
      <div class="content" :style="`height:${centerHeight}px`">
        <!-- 二级分类 start -->
        <div class="class-b">
          <div class="item show-one" :class="tabBIndex == 0 ? 'active': ''" @click="classClist(1, 0)">
            我的常用
          </div>
          <div class="item show-one" @click="classClist(1, 1)" :class="tabBIndex == 1 ? 'active': ''">
            为您推荐
          </div>
        </div>
        <!-- 二级分类 end -->

        <div class="right-content">
          <!-- 商品列表 start -->
          <div class="goods-list">
            <ul class="goods-ul"
            v-infinite-scroll="loadMore"
            infinite-scroll-disabled="loading"
            infinite-scroll-immediate-check="false"
            infinite-scroll-distance="10">
              <li class="goods-li" v-for="(item, index) in commodityList" :key="index" @click="$router.push({path: '/shopping/details', query: {id: item.id}})" >
                <div class="item">
                  <img class="good-img" v-lazy="requestImgUrl($transObjUndefined(JSON.parse(item.thumbnail)[0]).url)" :key="requestImgUrl($transObjUndefined(JSON.parse(item.thumbnail)[0]).url)" alt="">
                  <div class="right-goods-item">
                    <div class="good-name show-one">{{item.name}}</div>
                    <div class="ms show-one" v-if="!item.specList[0].seckill && item.specList[0].minLimit == 1 && !item.tags ">{{item.description}}</div>
                    <div class="tags-box show-one" v-else>
                      <div class="tag m-kill">月销{{item.virtualMonthlySales + item.monthlySales}}</div>
                      <div class="tag m-kill" v-if="item.specList[0].seckill">秒杀</div>
                      <template v-if="item.tags">
                        <div class="tag tags-item"  v-for="(tagItem, tagIndex) in (item.tags).split(',')" :key="tagIndex">
                          {{tagItem}}
                        </div>
                      </template>
                      <div class="tag buy-from" v-if="item.specList[0].minLimit > 1">{{item.specList[0].minLimit}}{{item.specList[0].unitName!=''?item.specList[0].unitName:(item.unitName)}}起购</div>
                    </div>

                    <div class="price-details"  v-if="item.unitName != item.specList[0].minUnitName && item.specList[0].unitQuantity > 1 && !item.specsShow && item.specList[0].price > 0">
                      <!-- <span class="unit-tips" v-if="item.unitName != item.specList[0].minUnitName && item.specList[0].unitQuantity > 1">约{{item.specList[0].unitQuantity}}{{item.specList[0].minUnitName}}</span> -->
                      <span :class="item.unitName != item.specList[0].minUnitName && item.specList[0].unitQuantity > 1 ? 'hui' : ''">
                        <span class="unit">￥</span>{{(Number(item.specList[0].price) + item.specList[0].deposit)}}<span class="unit">{{item.specList[0].unitName!=''? '/'+item.specList[0].unitName:(item.unitName != '' ? `/${item.unitName}` : '')}}</span>
                      </span>
                      <div class="deposit" v-if="item.specList[0].deposit > 0">(含押金{{item.specList[0].deposit}}元)</div>
                    </div>
                    <div class="bottom">
                      <div class="price-box">
                        <div class="is-certified" v-if="item.specList[0].unitPrice < 0 && (Number(item.specList[0].price)) < 0 ">{{isCertified()}}</div>
                        <div v-else>
                          <div class="present-price" v-if="!item.specsShow">
                            <div class="unit">￥</div>
                            <div class="money" v-if="item.unitName != item.specList[0].minUnitName && item.specList[0].unitQuantity > 1">
                              {{item.specList[0].unitPrice}}
                              <span class="unit">
                              {{item.specList[0].minUnitName != "" ? "/" + item.specList[0].minUnitName : ""}}
                              </span>
                            </div>
                            <div class="moeny" v-else>
                              {{item.specList[0].price +
                              item.specList[0].deposit}}<span
                                class="unit"
                              >{{item.specList[0].unitName!=''? '/'+item.specList[0].unitName:(item.unitName != "" ? "/"+item.unitName : "") }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="specs-btn-box jb-r-l" v-if="item.specList && item.specList.length > 1" @click.stop="changeSpecs(index)" >{{ item.specsShow ? "收起" : "规格" }}</div>
                      <div class="icon-car-btn" v-else @click.stop=" SelectProducts(item.id,item.specList[0].id,item.inventory)"></div>

                    </div>
                  </div>
                </div>
                <!-- 规格选项 start -->
                <div class="specs-box" :class="item.specsShow ? 'active' : ''" v-if="item.specList && item.specList.length > 1">
                  <div class="specs-item" v-for="(item2,index2) in item.specList" :key="index2">
                    {{item2.name}}  <span class="deposit" v-if="item2.deposit > 0 && item.specList[0].unitPrice > 0">(含押金{{item2.deposit}}元)</span>
                    <div class="is-certified" v-if="item.specList[0].unitPrice < 0 ">{{isCertified()}}</div>
                    <span class="present-price" v-else>
                      <span class="unit">￥</span>
                      <span class="moeny">{{item2.price + item2.deposit}}</span>
                      <span class="unit">{{item2.unitName!=''? '/'+item2.unitName:(item.unitName != "" ? "/"+item.unitName : "") }}</span>
                    </span>
                    <div class="specs-btn jb-r-l" @click.stop="SelectProducts(item.id, item2.id)">+</div>
                  </div>
                </div>
                <!-- 规格选项 end -->
              </li>
            </ul>
            <no-data v-if="nodata"></no-data>
          </div>
          <!-- 商品列表 end -->

        </div>
      </div>
    </div>
    <tabBar ref="tabbar"></tabBar>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { requestImgUrl, isWeiXin, funcUrlDel} from "@/utils/common";
import { isHttp, isCertified} from "@/utils/utils";
export default {
  data() {
    this.isHttp = v => isHttp(v);
    this.requestImgUrl = (url, privates) => requestImgUrl(url, privates);
    this.isWeiXin = () => isWeiXin();
    this.isCertified = () => isCertified();
    return {
      banners: [],
      // 搜索
      searchText: '',
      //暂无数据
      nodata: false,
      // 数据加载
      loading: false,
      classA: [],
      classB: [],
      classC: [],
      tabAIndex:0,
      tabBIndex:0,
      tabCIndex:0,
      // 商品列表
      commodityList: [],
      // 中间内容区域
      centerHeight: 0,
      uid: localStorage.getItem('uid'),
      pages: 1,
      pagenum: 1,
      obj: {},
    }
  },
  mounted() {
    // 计算可视区域
    setTimeout(()=> {
      this.jsHeight();
    },100)

    // 广告
    this.getAd({
      positionCode:'M002'
    }).then((res)=> {
      this.banners = res.data;
    });
    // 获取数据
    this.getGoodsList(0)
  },
  methods: {
    searchFuc() {
      this.getGoodsList(this.tabBIndex);
    },
    // 加载更多
    loadMore() {
      this.loading = true;
      let index = this.tabCIndex;
      let pageNum = this.pageNum + 1;
      let pages = this.pages;
      if (pageNum > pages) {
        this.$toast("没有更多了~")
      } else {
        this.getGoodsList(this.tabBIndex,'add', pageNum);
      }
    },
    // 选择商品事件
    SelectProducts(commodityId, specId, inventory) {
      if (inventory <= 0) {
        this.$toast("卖光啦！");
         return false;
      }
      this.AddCommodityCart({
        commodityId: commodityId,
        specId: specId,
        uid: this.uid,
      }).then((res)=> {
        if (res.status == 200) {
          this.$toast("加入购物车成功")
          // 获取购物车总数
          this.getCommodityCount({
            uid: this.uid
          }).then((res)=> {
            this.goodsNumMuta(res.data.quantity)
          })
        }
      })
    },
    // 显示规格列表
    changeSpecs(index) {
      let commodityList = this.commodityList;
      commodityList[index].specsShow = !commodityList[index].specsShow;
      this.commodityList = commodityList;
    },
    // 第三级查询列表
    classClist(id, index, thirdCode) {
      this.pageNum = 1;
      this.commodityList = [];
      if (index || index == 0) {
        this.tabBIndex = index;
      }
      this.getGoodsList(index);
    },
    // 获取商品列表
    getGoodsList(index, add, pageNum) {
      let obj = {
        pageNum: pageNum ? pageNum : 1,
        name: this.searchText,
      }
      if (index == 0) {
        obj.ordered = 1;
      } else {
        obj.habitRecommend = 1;
      }

      this.obj = obj;

      this.$Indicator.open({
        text: '加载中...',
        spinnerType: 'snake',
      });
      this.GetCommodityList(obj).then((msg)=> {
        let arrs = []
        msg.data.list.forEach(element => {
          element.specsShow = false;
          arrs.push(element);
        });
        if (add) {
          this.commodityList = this.commodityList.concat(arrs);
        } else {
          this.commodityList = arrs;
        }
        this.loading = false;
        this.pageNum = msg.data.pageNum;
        this.pages = msg.data.pages;
        this.nodata = this.commodityList.length > 0 ? false : true;
        this.$Indicator.close();
      })
    },

    jsHeight() {
      let innerHeight = window.innerHeight;
      const headerHeight = isWeiXin() ? 0 : 40;
      const tabberHeight = this.$refs.tabbar.$el.offsetHeight;
      const searchHeight = this.$refs.searchbox.offsetHeight;
      let height = innerHeight - headerHeight - tabberHeight - searchHeight;
      this.centerHeight = height;
    },



    ...mapActions(['getAd']),
    ...mapActions("shopping", ["GetCommodityList", "CommodityClass", "getCommodityCount", "AddCommodityCart"]),
    ...mapMutations(["goodsNumMuta"])
  },
  computed: {
    ...mapState(["goodsNum"]),
  },
}
</script>
<style lang='less' scoped>
.home-fixed {
  position: fixed;
  top: 40px;
  left: 0;
  width: 100%;
  height: 100%;
}

.home-fixed-weixin {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
}
.money{
  display: flex;
}
.price-details{
  display: flex;
  margin-top: .15rem;
  margin-bottom: -0.15rem;
  align-items: center;
  .active{
    color: #ff7241;
  }
  .hui{
    color: #8a8a8a;
    font-weight: normal;
    font-size: .25rem;
  }
  .no-unitName{
    margin-top: .15rem;
    display: flex;
    align-items: center;
  }
  .unit{
    font-size: 0.2rem;
  }
}
.deposit{
  color: #ccc;
  font-size: .24rem;
  margin-left: .15rem;
}
.tags-box{
  white-space: nowrap;
  max-width: 3rem;
}
.gd-banner{
  margin-top: .2rem;
}
.specs-box{
  max-height: 0;
  overflow: hidden;
  &.active{
    max-height: 5rem;
  }
}
.specs-btn-box{
  color: #fff;
  font-size: .24rem;
  padding: .1rem;
  border-radius: .1rem;
}
.specs-item{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: .28rem;
  height: .8rem;
  padding: 0 .25rem;
  .is-certified{
    margin-top: 0rem;
    margin-left: .05rem;
    font-size: .24rem;
    line-height: 1.2;
  }

  &:last-child {
    border-bottom: 1px solid #f7f7f7;
  }
  .present-price{
    margin-left: .1rem;
  }
  .specs-btn{
    height: .48rem;
    width: .48rem;
    border-radius: 50%;
    text-align: center;
    line-height: .48rem;
    color: #fff;
    margin-left: .2rem;
  }
}
.price-box{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .org-price{
    font-size: .24rem;
    color: #bfbfbf;
    text-decoration: line-through;
    line-height: 1;
    margin-left: .1rem;
  }
  .present-price{
    display: flex;
    align-items: flex-end;
    color: #ff7241;
    .unit{
      /*line-height: 1.8;*/
      font-size: .24rem;
    }
    .moeny{
      font-size: .32rem;
      display: flex;
      align-items: flex-end;
    }
  }
.goods-list{
  background: #fff;
  overflow-y: scroll;
  
  .goods-ul{
    padding: 0 .1rem;
    .goods-li{
      background: #fff;
      margin-bottom: .2rem;
      .item{
        position: relative;
        display: flex;
        border-radius: .1rem;
        overflow: hidden;
        padding-bottom: .25rem;
        .right-goods-item{
          padding:0 0 0 .25rem;
          flex: 1;
          .ms{
            font-size: .24rem;
            color: #bfbfbf;
            margin-top: .1rem;
          }
        }
        .good-img{
          width: 1.6rem;
          height: 1.6rem;
          border-radius: .1rem;
        }
      }
      .good-name{
        font-size: .26rem;
        color: #212121;
      }
      .bottom{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: .2rem;
      }
    }
  }
  .title{
    display: flex;
    justify-content: space-between;
    padding: .5rem .2rem 0;
    color: #000;
    .all-goods{
      font-size: .28rem;
      color: #727272;
      font-weight: normal;
    }
  }
}
.class-c{
  margin-top: .2rem;
  .item-slide{
    width: auto;
    .item{
      font-size: .26rem;
      color: #727272;
      height: .45rem;
      padding: 0 .2rem;
      line-height: .45rem;
      background: #fff;
      &.active{
        background: #ffefea;
        color: #ff7241;
      }
    }
  }
}
.gd-swiper{
  height: 1.3rem;
}
.right-content{
  padding-left: 2.1rem;
  display: flex;
  flex-direction: column;
  padding-right: .2rem;
  box-sizing: border-box;
  background: #fff;
  height: 100%;
  .goods-list{
    background: #fff;
    flex: 1;
    margin-top: .2rem;
  }
}
.content-fixed{
  // position: fixed;
  // top: 0;
  // left: 0;
  // padding-bottom: 1.2rem;
  width: 100%;
  height: 100%;
  // padding-top: 40px;
  display: flex;
  flex-direction: column;
  z-index: 99999;
}
.content{
  position: relative;
  .class-b{
    overflow-y: scroll;
    min-width: 1.9rem;
    max-width: 1.9rem;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #f7f7f7;
    .item{
      height: 1rem;
      line-height: 1rem;
      width: 1.9rem;
      font-size: .28rem;
      color: #212121;
      position: relative;
      padding-left: .2rem;
      padding-right: .2rem;
      text-align: center;
      &.active{
        color: #ff7241;
        background: #fff;
        &::after{
          position: absolute;
          top: 50%;
          left: 0;
          width: .05rem;
          height: .44rem;
          background: #ff7241;
          content: "";
          margin-top: -.22rem;
        }
      }
    }
  }
}
.class-a{
  margin-top: .3rem;
  .item{
    width: auto;
    font-size: .28rem;
    color: #727272;
    &.active{
      color: #ff7241;
      font-weight: bold;
    }
  }
}
.shopping-home{
  padding-top: .2rem;
  display: flex;
  flex-direction: column;
  padding-bottom: .2rem;
  height: 1.1rem;
}
.search-box{
  width: 100%;
  height: .68rem;

  position: relative;
  display: flex;
  .input-search{
    flex: 1;
    border-radius: .3rem;
    position: relative;
    background: #fff;
  }
  .search-btn{
    border-radius: .3rem;
    color: #fff;
    width: 1.5rem;
    text-align: center;
    margin-left: .2rem;
    line-height: .68rem;
  }
  .icon-search-fdj{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin-left: -.75rem;
  }
  .search-input{
    width: 100%;
    height: 100%;
    font-size: .26rem;
    text-align: center;
  }
}
</style>